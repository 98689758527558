export const pt = {
    "n1": "primeira página",
    "n2": "Ordem",
    "n3": "troca",
    "n4": "equipe",
    "n5": "meu",
    "t1": "Algo deu errado",
    "t2": "Copiar com sucesso",
    "t3": "Por favor, preencha as informações integralmente",
    "t4": "carregando…",
    "t5": "Login com sucesso",
    "t6": "A pontuação deve ser maior que 3 antes de enviar",
    "t7": "Submissão bem -sucedida",
    "t8": "Equilíbrio insuficiente, não pode ser coletado",
    "t9": "A submissão falhou",
    "t10": "Por favor, vincule o endereço primeiro e a página do centro pessoal será redirecionada para a página do centro pessoal.",
    "t11": "O endereço foi vinculado, entre em contato com o atendimento ao cliente para modificá -lo",
    "t12": "A modificação foi bem -sucedida",
    "t13": "Modificação falhou",
    "t14": "Entre em contato com o atendimento ao cliente para modificar sua senha",
    "t15": "Erro de formato de senha",
    "t16": "Retirada bem -sucedida",
    "t17": "Para retirar dinheiro",
    "t18": "Retirada mínima em dinheiro",
    "t19": "As senhas são inconsistentes duas vezes",
    "t20": "dica",
    "t21": "Não posso pular por enquanto",
    "t22": "Erro de formato de senha",
    "t23": "Registrado com sucesso",
    "t24": "Por favor, insira o código da área",
    "t25": "Não mais ainda",
    "l1": "linguagem",
    "l2": "Por favor, insira o número da sua conta",
    "l3": "Por favor, insira sua senha",
    "l4": "lembrar",
    "l5": "Conecte-se",
    "l6": "Vá para se registrar",
    "l7": "Sem conta",
    "l8": "Número de telefone",
    "l9": "Apelido",
    "l10": "senha",
    "l11": "Confirme sua senha",
    "l12": "Código de convite",
    "l13": "registrar",
    "l14": "Já tem uma conta",
    "l15": "Vá para fazer login",
    "l16": "Por favor, insira uma senha de 6-16 dígitos",
    "bz1": "ajuda",
    "bz2": "Perguntas frequentes",
    "bz3": "Regras de negociação",
    "bz4": "Como retirar dinheiro",
    "bz5": "Como completar",
    "bz6": "1. O que está a Amazon Review Robot?",
    "bz7": "A Amazon Review Robot é uma empresa de promoção de marketing que ajuda os comerciantes da Amazon Review Robot Online Store em todo o mundo a obter mais vendas de pedidos e aumentar os dados de navegação para a Amazon Review Robot Online Store. Estamos comprometidos em estabelecer um modelo de promoção de marketing lucrativo de três partes entre a Amazon Review Robot e os comerciantes e os consumidores. Combinando a mais recente tecnologia Blockchain P2P, conecta rapidamente consumidores e comerciantes através do USDT (TRC20, ERC20). Os usuários registrados podem receber comissões em pedidos, enquanto os comerciantes podem aumentar os dados de vendas da loja. É o modelo de lucro mais recente no modelo Blockchain da Internet!",
    "bz8": "2.Como a Amazon Review Robot funciona?",
    "bz9": "Através do feedback diário do Amazon Review Robot, isso mostra que, ao vender produtos que precisam de melhorias são vendidos no robô de revisão da Amazon, os usuários precisam apenas clicar facilmente no pedido e o sistema gerará automaticamente assinaturas de pedidos. Os usuários pagam os valores do pedido através do USDT da blockchain e recebem comissões diárias.",
    "bz10": "3. Por que há alguma diferença nos preços da mesma moeda nas duas transações?",
    "bz11": "As diferenças de preços são causadas por todos os fatores que não são propícios à circulação livre de moedas, incluindo a velocidade de transferência de moeda, condições de rede, restrições de acesso à moeda, reconhecimento das pessoas por diferentes regiões e até mesmo os tipos de pares de transações fornecidas pelas trocas e transações. Portanto, a mesma moeda pode criar diferenças de preço em diferentes transações.",
    "bz12": "4. Lucro do investimento?",
    "bz13": "Quanto maior o preço do item que você obtém, maior o lucro que você obtém no pedido. Enquanto isso, a Amazon Review Robot aloca aleatoriamente grandes ordens de comissão.",
    "bz14": "Todos os dias, o sistema gera e distribui automaticamente 60 pedidos de produto para os usuários. Grandes ordens de comissão são alocadas aleatoriamente.",
    "bz15": "O USDT pode retirar dinheiro após 60 pedidos. (Se 60 pedidos não forem concluídos, o sistema parará automaticamente para o volume restante do pedido do dia)",
    "bz16": "Cada conta só pode estar vinculada a uma conta do USDT.",
    "bz17": "Depois de concluir a entrega diária de pedidos, você pode retirar dinheiro normalmente e chegar dentro de 24 horas após o início da retirada, e não há restrições de retirada.",
    "bz18": "Antes da recarga, vincule seu endereço de retirada (suporta o endereço do USDT TRC-20).",
   'bz19': "Não é permitido recarregar ou transferir dinheiro entre contas relacionadas. Este é um comportamento malicioso de captura de ordens e afeta seriamente as regras de operação da plataforma. Se tal comportamento for encontrado, o sistema congelará imediatamente a conta e desqualificará o trabalho.",
    "ty1": "Claro",
    "ty2": "Cancelar",
    "ty3": "enviar",
    "ty4": "endereço",
    "s1": "Renda cumulativa",
    "s2": "Servir",
    "s3": "sobre nós",
    "s4": "ajuda",
    "s5": "Parceiros",
    "s6": "Meu serviço",
    "d1": "Não concluído",
    "d2": "Concluído",
    "d3": "Ainda não há dados",
    "d4": "Número único",
    "d5": "Tempo de negociação",
    "d6": "Quantia",
    "d7": "Número atual de tarefas",
    "d8": "Ganhos esperados",
    "d9": "Precisar",
    "d10": "pontuação",
    "j1": "Saldo da conta",
    "j2": "Introdução à transação",
    "j3": "Amazon Review Robot Use o feedback do Amazon Review Robot diariamente para mostrar os produtos Amazon Review Robot vende que precisa de melhorias. O usuário só precisa clicar no pedido e o sistema gerará automaticamente a assinatura do pedido. Os usuários pagam o valor do pedido através do Blockchain do USDT e recebem uma comissão de mais de 0,6% a cada vez.",
    "j4": "Comece a combinar",
    "j5": "Ganhos de hoje",
    "j6": "Número concluído",
    "j7": "Número total de tarefas",
    "td1": "Equilíbrio",
    "td2": "comissão",
    "td3": "receber",
    "td4": "Número total de pessoas",
    "td5": "usuário",
    "td6": "contribuir",
    "td7": "quantidade",
    "td8": "estado",
    "g1": "Cancelamento",
    "g2": "Ligue",
    "g3": "Convide amigos",
    "g4": "Convite agora",
    "g5": "Você receberá uma proporção correspondente de comissão para cada lucro que ganha depois de registrar um amigo",
    "g6": "informações pessoais",
    "g7": "Detalhes de financiamento",
    "g8": "Recarregar o registro",
    "g9": "Registro de retirada",
    "g10": "linguagem",
    "g11": "Log Out",
    "g12": "Você tem certeza de fazer logot?",
    "yh1": "Informações do usuário",
    "yh2": "Rever",
    "yh3": "configurar",
    "yh4": "Telefone",
    "yh6": "Senha original",
    "yh7": "Nova Senha",
    "mx1": "Receber comissão",
    "mx2": "renda",
    "mx3": "Recusa de retirada",
    "mx4": "tempo",
    "mx5": "Recarregue com sucesso",
    "mx6": "Rejeitado",
    "mx7": "Pendente",
    "mx8": "Endereço de retirada",
    "mx9": "Saldo disponível",
    "mx10": "Recibo real",
    "mx11": "Por favor, insira o valor da retirada",
    "cz2": "cópia",
    "cz3": "Seleção de moeda filipina",
    "cz4": "Pagar",
    "cz5": "O endereço de depósito suporta apenas TRC20-USDT e o valor mínimo do depósito é 20USDT.",
    "cz6": "O endereço de depósito suporta apenas o ERC20, o valor mínimo do depósito é 20USDT",
    "cz1": "O endereço de depósito suporta apenas o ERC20, o valor mínimo do depósito é 20USDT",
    "qy1": "Código de área",
    "yq1": "Convide amigos",
    "yq2": "Código de convite",
    "yq3": "Convide amigos e desfrute de grandes lucros",
    "yq4": "Vá e convide"
  }
  