// 荷兰
export const nl = {
    //Navigatiebalk
    'n1': 'Thuis',
    'n2': 'Bestelling',
    'n3': 'Transactie',
    'n4': 'Team',
    'n5': 'mijn',
    // snelle klasse
    't1': 'Er is een fout opgetreden',
    't2': 'Kopiëren succesvol',
    't3': 'Vul de gegevens volledig in',
    't4': 'Laden...',
    't5': 'Inloggen succesvol',
    't6': 'De score moet groter zijn dan 3 om in te dienen',
    't7': 'Inzending succesvol',
    't8': 'Onvoldoende saldo, niet in staat om te ontvangen',
    't9': 'Inzending mislukt',
    't10': 'Bind eerst het adres, dan wordt u doorgestuurd naar de persoonlijke centrumpagina',
    't11': 'Het adres is gebonden, neem contact op met de klantenservice om het te wijzigen',
    't12': 'Wijziging succesvol',
    't13': 'Wijziging mislukt',
    't14': 'Neem contact op met de klantenservice om uw wachtwoord te wijzigen',
    't15': 'Fout in wachtwoordformaat',
    't16': 'Opname succesvol',
    't17': 'Maximale opnames',
    't18': 'Minimale opname',
    't19': 'Twee wachtwoorden zijn inconsistent',
    't20': 'Aanwijzing',
    't21': 'Kan tijdelijk niet springen',
    't22': 'Fout in wachtwoordformaat',
    't23': 'Registratie succesvol',
    't24': 'Voer alstublieft het netnummer in',
    't25': 'Niet meer',
    //aanmelden
    'l1': 'Taal',
    'l2': 'Vul uw rekeningnummer in',
    'l3': 'Voer wachtwoord in',
    'l4': 'onthoud',
    'l5': 'Inloggen',
    'l6': 'Ga naar registratie',
    'l7': 'Geen account',
    'l8': 'Mobiel telefoonnummer',
    'l9': 'bijnaam',
    'l10': 'wachtwoord',
    'l11': 'Bevestig wachtwoord',
    'l12': 'uitnodigingscode',
    'l13': 'Registreren',
    'l14': 'Heb al een account',
    'l15': 'Ga naar inloggen',
    'l16': 'Voer een wachtwoord van 6 tot 16 cijfers in',

    //hulp
    'bz1': 'Hulp',
    'bz2': 'Veelgestelde vragen',
    'bz3': 'Handelsregels',
    'bz4': 'Hoe geld opnemen',
    'bz5': 'Hoe op te laden',
    'bz6': '1.Wat is Amazon Review RoBoT? ',
    'bz7': 'Amazon Review RoBoT is een marketing- en promotiebedrijf dat verkopers van Amazon Review RoBoT-onlinewinkels over de hele wereld helpt meer orderverkopen te genereren en de browsegegevens van Amazon Review RoBoT-onlinewinkels te vergroten. We streven ernaar een tripartiet winstgevend marketing- en promotiemodel op te zetten tussen Amazon Review RoBoT, verkopers en consumenten. Gecombineerd met de nieuwste P2P-blockchaintechnologie zijn consumenten en verkopers snel verbonden via USDT (TRC20, ERC20). Geregistreerde gebruikers kunnen commissie krijgen op bestellingen, terwijl verkopers de verkoopgegevens van hun winkel kunnen vergroten. Het is het nieuwste winstmodel in het internet-blockchain-model! ',
    'bz8': '2.Hoe werkt Amazon Review RoBoT?',
    'bz9': 'Uit de dagelijkse feedback van Amazon Review RoBoT blijkt dat producten die verbetering behoeven in Amazon Review RoBoT worden verkocht. Gebruikers hoeven alleen maar eenvoudig op de bestelling te klikken en het systeem genereert automatisch een bestelabonnement. Gebruikers betalen het orderbedrag via blockchain USDT en ontvangen dagelijkse commissies. ',
    'bz10': '3. Waarom zijn de prijzen van dezelfde valuta verschillend in twee transacties? ',
    'bz11': "Prijsverschillen worden veroorzaakt door alle factoren die niet bevorderlijk zijn voor het vrije verkeer van valuta, waaronder de snelheid van valutaoverdrachten, netwerkomstandigheden, toegangsbeperkingen voor valuta, valutaherkenning door mensen in verschillende regio's en zelfs de handelsparen aangeboden door soorten uitwisselingen, transacties, enz.Daarom kan dezelfde valuta prijsverschillen hebben bij verschillende transacties. ",
    'bz12': '4. Beleggingswinst? ',
    'bz13': 'Hoe hoger de productprijs die u krijgt, hoe hoger de orderwinst die u krijgt. Tegelijkertijd verdeelt Amazon Review RoBoT willekeurig grote commissiebestellingen. ',
    'bz14': 'Elke dag genereert en distribueert het systeem automatisch 60 productbestellingen aan gebruikers. Gebruikers voltooien de betaling voor elke bestelling via USDT en ontvangen een commissie van 0.6%. Grote commissieorders worden willekeurig toegewezen. ',
    'bz15': 'USDT kan na 60 bestellingen worden ingetrokken. (Als 60 bestellingen niet zijn voltooid, stopt het systeem automatisch tot de resterende bestellingen van de dag)',
    'bz16': 'Elk account kan slechts aan één USDT-account worden gekoppeld. Als er een fout optreedt, neem dan contact op met de klantenservice',
    'bz17': 'Na voltooiing van de levering van dagelijkse bestellingen kunt u normaal geld opnemen. Het komt binnen 24 uur na het starten van de opname aan en er is geen opnamelimiet',
    'bz18': 'Bind uw opnameadres (ondersteunt TRC-20) USDT-adres voordat u opwaardeert. U kunt op Opladen klikken op de APP of webpagina en de blockchain (TRC-20) selecteren die u gebruikt om op te laden. ',
  'bz19': "Het opwaarderen of overboeken van geld tussen gerelateerde accounts is niet toegestaan. Dit is kwaadwillig ordergrijpgedrag en heeft ernstige gevolgen voor de werkingsregels van het platform. Als dergelijk gedrag wordt aangetroffen, zal het systeem het account onmiddellijk bevriezen en het werk diskwalificeren.",
    //moduletekst
    //Veelgebruikte woorden
    'ty1': 'OK',
    'ty2': 'Annuleren',
    'ty3': 'Verzenden',
    'ty4': 'adres',
    //voorpagina
    's1': 'Cumulatief inkomen',
    's2': 'Dienst',
    's3': 'Over ons',
    's4': 'Hulp',
    's5': 'Partner',
    's6': 'Mijn dienst',
    //Volgorde
    'd1': 'Onvoltooid',
    'd2': 'Voltooid',
    'd3': 'Nog geen gegevens',
    'd4': 'oneven getal',
    'd5': 'Handelstijd',
    'd6': 'bedrag',
    'd7': 'Huidig ​​aantal taken',
    'd8': 'Verwacht rendement',
    'd9': 'Meer nodig',
    'd10': 'Beoordeling',
    //handel
    'j1': 'Rekeningsaldo',
    'j2': 'Handelsintroductie',
    'j3': 'Amazon Review RoBoT gebruikt de feedback van Amazon Review RoBoT elke dag om de door Amazon Review RoBoT verkochte producten te laten zien die verbetering behoeven. Gebruikers hoeven alleen maar op de bestelling te klikken en het systeem genereert automatisch een bestelhandtekening. Gebruikers betalen het orderbedrag via de USDT-blockchain en kunnen elke keer meer dan 0,6% commissie ontvangen, en het systeem verdeelt willekeurig grote beloningsorders. ',
    'j4': 'Begin met matchen',
    'j5': 'De inkomsten van vandaag',
    'j6': 'Nummer voltooid',
    'j7': 'Totaal aantal taken',
    //team
    'td1': 'Balans',
    'td2': 'commissie',
    'td3': 'Ontvangen',
    'td4': 'Totaal aantal mensen',
    'td5': 'gebruiker',
    'td6': 'bijdrage',
    'td7': 'Aantal',
    'td8': 'status',
    //Persoonlijk centrum
    'g1': 'Intrekken',
    'g2': 'Opladen',
    'g3': 'Vrienden uitnodigen',
    'g4': 'Nu uitnodigen',
    'g5': 'Voor elke winst die uw vriend maakt na registratie, ontvangt u een overeenkomstig deel van de commissie',
    'g6': 'Persoonlijke informatie',
    'g7': 'Fondsgegevens',
    'g8': 'Opname opnieuw opladen',
    'g9': 'Opnamerecord',
    'g10': 'Taal',
    'g11': 'Uitloggen',
    'g12': 'Weet je zeker dat je wilt uitloggen?',
    //Gebruikers informatie
    'yh1': 'Gebruikersinformatie',
    'yh2': 'Wijzigen',
    'yh3': 'instelling',
    'yh4': 'telefoon',
    'yh5': 'Weet je zeker dat je wilt uitloggen?',
    'yh6': 'Origineel wachtwoord',
    'yh7': 'Nieuw wachtwoord',
    //Details
    'mx1': 'Commissie-incasso',
    'mx2': 'winst',
    'mx3': 'Opname afgewezen',
    'mx4': 'tijd',
    'mx5': 'Opladen succesvol',
    'mx6': 'Afgewezen',
    'mx7': 'in behandeling',
    'mx8': 'Opnameadres',
    'mx9': 'Beschikbaar saldo',
    'mx10': 'Werkelijke aankomst',
    'mx11': 'Voer het opnamebedrag in',
    //opwaarderen
    'cz1': 'Het stortingsadres ondersteunt alleen ERC20 en het minimale stortingsbedrag is 20USDT',
    'cz2': 'Kopiëren',
    'cz3': 'Fiat-valutaselectie',
    'cz4': 'betaling',
    'cz5': 'Het stortingsadres ondersteunt alleen TRC20-USDT en het minimale stortingsbedrag is 20USDT',
    'cz6': 'Het stortingsadres ondersteunt alleen ERC20 en het minimale stortingsbedrag is 20USDT',
    'cz1': 'Het stortingsadres ondersteunt alleen ERC20 en het minimale stortingsbedrag is 20USDT',
    'cz1': 'Het stortingsadres ondersteunt alleen ERC20 en het minimale stortingsbedrag is 20USDT',
    //gebied
    'qy1': 'netnummer',
    'yq1': 'Vrienden uitnodigen',
    'yq2': 'uitnodigingscode',
    'yq3': 'Nodig vrienden uit en geniet van grote voordelen',
    'yq4': 'Ga uitnodigen',
}
